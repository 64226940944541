import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleResetPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setError(error.message);
      setMessage(null);
    } else {
      setError(null);
      setMessage('Password reset email sent! Check your inbox.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-12">
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        <img
          className="mx-auto h-20 w-auto mb-6"
          src="/chip.png"
          alt="Poker Chip Logo"
        />
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Reset your password</h2>
        {message && (
          <div className="text-green-500 mb-4 font-medium text-sm">
            {message}
          </div>
        )}
        {error && (
          <div className="text-red-500 mb-4 font-medium text-sm">
            {error}
          </div>
        )}
        <form className="space-y-6" onSubmit={handleResetPassword}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-900">
              Email address
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-rose-500 sm:text-sm sm:leading-6"
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-gradient-to-r from-violet-400 to-violet-600 text-white font-medium rounded-md hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500"
          >
            Reset Password
          </button>
        </form>
        <p className="mt-6 text-center text-gray-500 text-sm">
          Remembered your password?{' '}
          <Link to="/login" className="font-semibold text-violet-400 hover:text-gray-800">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
