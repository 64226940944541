import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

const Ftl: React.FC = () => {
  const [name, setName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    try {
      // Get user's UUID from the session information
      const { data: { user } } = await supabase.auth.getUser()
  
      // Store user data in the database
        const { data, error } = await supabase
        .from('userData')
        .insert([
        { uuid: user?.id, name: name },
        ])
        .select()
        
  
      if (error) {
        throw error;
      }
  
      // Navigate to another page after successful registration
      navigate('/home');
    } catch (error) {
      setError('Error occurred while processing your request.'+ error);
    }
  };
  

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow-md">
        <h2 className="text-2xl font-bold text-center">First Name</h2>
        {error && <div className="text-red-600">{error}</div>}
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={handleNameChange}
              required
              className="w-full px-3 py-2 mt-1 border rounded shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Ftl;
