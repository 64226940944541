import React from 'react';
import { Link } from 'react-router-dom';

const App: React.FC = () => {
  const spinAnimation = {
    animation: 'spin 7s linear infinite',
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-12">
      <style>{keyframesStyle}</style>
      <div className="w-full max-w-md bg-white rounded-lg shadow-md p-8 flex flex-col items-center">
        <img
          className="mx-auto h-20 w-auto mb-6"
          src="/chip.png"
          alt="Spinning Icon"
          style={spinAnimation}
        />
        <div className="flex flex-col items-center space-y-4 w-full">
          <button className="w-full py-2 px-4 bg-gradient-to-r from-violet-400 to-violet-600 text-white font-medium rounded-md hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
            <Link to="/create-lobby" className="block text-center w-full">
              Create Lobby
            </Link>
          </button>
          <button className="w-full py-2 px-4 bg-gradient-to-r from-violet-400 to-violet-600 text-white font-medium rounded-md hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
            <Link to="/join-lobby" className="block text-center w-full">
              Join Lobby
            </Link>
          </button>
          <button className="w-full py-2 px-4 bg-gradient-to-r from-violet-400 to-violet-600 text-white font-medium rounded-md hover:bg-rose-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-rose-500">
            <Link to="/login" className="block text-center w-full">
              Sign in to Live Lobby
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
