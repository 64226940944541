import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { supabase } from './supabaseClient';
import Login from './login';
import SignUp from './signUp';
import Home from './home'
import Ftl from './ftl'
import Resetpassword from './resetpassword'

const App: React.FC = () => {
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: session }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);


    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/home" element={<Home />} />
            <Route path="/ftl" element={<Ftl />} />
            <Route path="/resetpassword" element={<Resetpassword />} />
          </Routes>
        </div>
      </Router>
    );
  }


export default App;
